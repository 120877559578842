/**
 * @flow
 */

/* eslint-disable */

'use strict';


/*::
import type { ConcreteRequest } from 'relay-runtime';
type Layout_root$ref = any;
export type errorLayoutQueryVariables = {|
  lang: string
|};
export type errorLayoutQueryResponse = {|
  +$fragmentRefs: Layout_root$ref
|};
export type errorLayoutQuery = {|
  variables: errorLayoutQueryVariables,
  response: errorLayoutQueryResponse,
|};
*/


/*
query errorLayoutQuery(
  $lang: String!
) {
  ...Layout_root_3iqx2P
}

fragment Layout_root_3iqx2P on Query {
  cms {
    navigation(uid: "main", lang: $lang) {
      nav_logo_link {
        __typename
        ... on CMSPage {
          template
          _meta {
            uid
            lang
          }
        }
      }
      phone_number
      body {
        __typename
        ... on CMSNavigationBodyNavigation_item {
          primary {
            label
            pathname
            nav_link {
              __typename
              ... on CMSPage {
                template
                _meta {
                  id
                  uid
                  lang
                }
              }
            }
          }
          fields {
            label
            pathname
            sub_nav_link {
              __typename
              ... on CMSPage {
                template
                _meta {
                  uid
                  lang
                }
              }
            }
          }
        }
      }
    }
    footerNavigation: navigation(uid: "footer", lang: $lang) {
      phone_number
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "lang"
  }
],
v1 = {
  "kind": "Variable",
  "name": "lang",
  "variableName": "lang"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "template",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uid",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lang",
  "storageKey": null
},
v6 = [
  (v2/*: any*/),
  {
    "kind": "InlineFragment",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "CMSMeta",
        "kind": "LinkedField",
        "name": "_meta",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "CMSPage",
    "abstractKey": null
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phone_number",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pathname",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "errorLayoutQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "Layout_root"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "errorLayoutQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CMS",
        "kind": "LinkedField",
        "name": "cms",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              (v1/*: any*/),
              {
                "kind": "Literal",
                "name": "uid",
                "value": "main"
              }
            ],
            "concreteType": "CMSNavigation",
            "kind": "LinkedField",
            "name": "navigation",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "nav_logo_link",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": null
              },
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "body",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CMSNavigationBodyNavigation_itemPrimary",
                        "kind": "LinkedField",
                        "name": "primary",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "nav_link",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v3/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "CMSMeta",
                                    "kind": "LinkedField",
                                    "name": "_meta",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "id",
                                        "storageKey": null
                                      },
                                      (v4/*: any*/),
                                      (v5/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "type": "CMSPage",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CMSNavigationBodyNavigation_itemFields",
                        "kind": "LinkedField",
                        "name": "fields",
                        "plural": true,
                        "selections": [
                          (v8/*: any*/),
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "sub_nav_link",
                            "plural": false,
                            "selections": (v6/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "CMSNavigationBodyNavigation_item",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": "footerNavigation",
            "args": [
              (v1/*: any*/),
              {
                "kind": "Literal",
                "name": "uid",
                "value": "footer"
              }
            ],
            "concreteType": "CMSNavigation",
            "kind": "LinkedField",
            "name": "navigation",
            "plural": false,
            "selections": [
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5821f261c5e2f5dd873739e8b132b93d",
    "id": null,
    "metadata": {},
    "name": "errorLayoutQuery",
    "operationKind": "query",
    "text": "query errorLayoutQuery(\n  $lang: String!\n) {\n  ...Layout_root_3iqx2P\n}\n\nfragment Layout_root_3iqx2P on Query {\n  cms {\n    navigation(uid: \"main\", lang: $lang) {\n      nav_logo_link {\n        __typename\n        ... on CMSPage {\n          template\n          _meta {\n            uid\n            lang\n          }\n        }\n      }\n      phone_number\n      body {\n        __typename\n        ... on CMSNavigationBodyNavigation_item {\n          primary {\n            label\n            pathname\n            nav_link {\n              __typename\n              ... on CMSPage {\n                template\n                _meta {\n                  id\n                  uid\n                  lang\n                }\n              }\n            }\n          }\n          fields {\n            label\n            pathname\n            sub_nav_link {\n              __typename\n              ... on CMSPage {\n                template\n                _meta {\n                  uid\n                  lang\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n    footerNavigation: navigation(uid: \"footer\", lang: $lang) {\n      phone_number\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5da11c6c09918099df5e723656a1b6f4';

export default node;
