// @flow

import * as React from 'react';
import { Flex, Box, useSystem } from 'react-system';
import { useRelayEnvironment, graphql, QueryRenderer } from 'react-relay';
import Head from 'next/head';
import { type ErrorItem, ErrorReporting } from '@realadvisor/error';
import { useTheme } from '../theme';
import { Layout } from '../shared/Layout';
import { useLocale } from '../controls/locale';
import { useUserData } from '../hooks/user-data';
import { useLocalStorageForDevelopmentPurposeOnly } from '../hooks/local-storage-development';
import { forceNonInteractive } from '../hooks/interactions';
import { useLog } from '../hooks/log';
import { InlineMarked } from '../controls/marked';

export type ErrorProps = {|
  // there is possibility that errors will be object in some edge cases
  errors?: $ReadOnlyArray<ErrorItem>,
  code: number,
  debug: boolean,
|};

const ErrorPageContent = ({ statusCode }: {| statusCode: number |}) => {
  const { mb, media } = useSystem();
  const { text, textColor } = useTheme();
  const { t } = useLocale();

  const statusCodes = {
    '400': t('errorTitle400'),
    '404': t('errorTitle404'),
    '500': t('errorTitle500'),
    '501': t('errorTitle501'),
  };

  const title = statusCodes[statusCode] || t('errorTitle');

  return (
    <Flex width={1} p={4} justifyContent="center" alignItems="center">
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>
          {statusCode}: {title}
        </title>
      </Head>

      <Flex css={textColor('mediumText')}>
        <Box width="420px">
          <h1 css={[text.h1, mb(4)]}>Ooops!</h1>
          <p css={[text.h4, mb(4)]}>{title}</p>
          <div css={[text.h6, mb(4)]}>
            <InlineMarked>{t('errorCode', { code: statusCode })}</InlineMarked>
          </div>
          <p css={text.h6}>
            {t('errorGoBackTo') + ' '}
            <a
              href="/"
              css={[textColor('blue800'), { textDecoration: 'none' }]}
            >
              {t('homePage')}
            </a>
          </p>
        </Box>
        <Flex
          width="420px"
          py="80px"
          justifyContent="flex-end"
          css={media({ display: ['none', 'none', 'block'] })}
        >
          <img
            src="/static/images/compass.png"
            width="375"
            alt={t('pageNotFound')}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export const ErrorPage = (props: ErrorProps): React.Node => {
  const logger = useLog();
  const { language } = useLocale();
  const { activePrismicLocales } = useUserData();
  const environment = useRelayEnvironment();

  if (typeof window === 'undefined') {
    // QueryRenderer has memory leak, return result immediately
    return (
      <Layout
        root={null}
        headerStyle="normal"
        // TODO: we may need to implement some special logic for locale switcher on error page
      >
        <ErrorComponent {...props} />
      </Layout>
    );
  }

  forceNonInteractive();

  return (
    <QueryRenderer
      variables={{
        lang: activePrismicLocales[language],
      }}
      environment={environment}
      query={graphql`
        query errorLayoutQuery($lang: String!) {
          ...Layout_root @arguments(lang: $lang)
        }
      `}
      render={({ props: data, error }) => {
        if (error != null) {
          logger.error(new Error('ErrorPage QueryRenderer Error'), error);
        }

        return (
          <Layout
            root={data ?? null}
            headerStyle="normal"
            // TODO: we may need to implement some special logic for locale switcher on error page
          >
            <ErrorComponent {...props} />
          </Layout>
        );
      }}
    />
  );
};

export const ErrorComponent = (props: ErrorProps): React.Node => {
  // Set it via dev console localStorage.setItem('debug', 'true')
  const debug = useLocalStorageForDevelopmentPurposeOnly('debug');

  return (
    <>
      <ErrorPageContent statusCode={props.code} />

      {(debug != null || props.debug) &&
        props.errors != null &&
        props.errors.length > 0 && <ErrorReporting errors={props.errors} />}
    </>
  );
};
